import { atom } from "recoil";
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

export const myInvitationsState = atom({
    key: 'myInvitations',
    default: [],
    effects_UNSTABLE: [
        persistAtom,
    ],
})
export const receivedInvitationsState = atom({
    key: 'receivedInvitations',
    default: [],
    effects_UNSTABLE: [
        persistAtom,
    ],
})
