import PropTypes from 'prop-types';
// @mui
import { Box } from '@mui/material';
// hooks
import useResponsive from '../../hooks/useResponsive';
// config
import { HEADER, NAV } from '../../config-global';
// components

// ----------------------------------------------------------------------

const SPACING = 8;

Main.propTypes = {
  sx: PropTypes.object,
  children: PropTypes.node,
};

export default function Main({ children, sx, ...other }) {

  const isDesktop = useResponsive('up', 'lg');

  return (
    <Box
      component="main"
      sx={{
        px: 2,
        pt: `${HEADER.H_DASHBOARD_DESKTOP + 60}px`,
        pb: `${HEADER.H_DASHBOARD_DESKTOP + SPACING}px`,
        overflowY: 'hidden'
      }}
    // bgcolor={'primary.lighter'}
    >
      {children}
    </Box>
  );
}

