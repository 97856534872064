import React, { useState, memo, useEffect } from 'react';
import { InputBase, Paper } from '@mui/material';
import Iconify from '../../../components/iconify';
import { IconButtonAnimate } from '../../../components/animate';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { searchQueryState } from 'src/recoil/atoms/tasks';

function Searchbar() {
  const [value, setValue] = useState('');
  const searchQuery = useRecoilValue(searchQueryState)
  const setSearchQuery = useSetRecoilState(searchQueryState)
  const resetSearchQuery = useResetRecoilState(searchQueryState)

  useEffect(() => {
    if (!searchQuery) {
      setValue('')
    }
  }, [searchQuery])

  const handleKeyUp = (event) => {
    if (event.key === 'Enter') {
      setSearchQuery(value);
    }
  };

  return (
    <Paper
      component="form"
      onSubmit={e => e.preventDefault()}
      sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}
    >
      <InputBase
        value={value}
        onChange={event => setValue(event.target.value)}
        onKeyUp={handleKeyUp}
        sx={{ ml: 1, flex: 1 }}
        placeholder="Search Tasks"
        endAdornment={searchQuery ? <IconButtonAnimate onClick={resetSearchQuery}>
          <Iconify icon="eva:close-fill" />
        </IconButtonAnimate> : <IconButtonAnimate onClick={_ => setSearchQuery(value)}>
          <Iconify icon="eva:search-fill" />
        </IconButtonAnimate>}
        inputProps={{ 'aria-label': 'search tasks' }}
      />

    </Paper>
  );
}

export default memo(Searchbar);