import { useState } from 'react';
import { Outlet } from 'react-router-dom';
// @mui
// hooks
// components
//
import Main from './Main';
import Header from './header';
import NavHorizontal from './nav/NavHorizontal';

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Header onOpenNav={handleOpen} />

      <NavHorizontal />

      <Main>
        <Outlet />
      </Main>
    </>
  );
}
