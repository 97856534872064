function path(root, sublink) {
    return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

export const PATH_AUTH = {
    login: path(ROOTS_AUTH, '/login'),
};

export const PATH_PAGE = {
    maintenance: '/maintenance',
    page404: '/404',
};

export const PATH_DASHBOARD = {
    root: ROOTS_DASHBOARD,
    user: {
        profile: path(ROOTS_DASHBOARD, '/user/profile'),
        account: path(ROOTS_DASHBOARD, '/user/account'),
    }
}