import PropTypes from 'prop-types';
// @mui
import { useTheme } from '@mui/material/styles';
import { Stack, AppBar, Toolbar } from '@mui/material';
// utils
import { bgBlur } from '../../../utils/cssStyles';
// hooks
import useOffSetTop from '../../../hooks/useOffSetTop';
import useResponsive from '../../../hooks/useResponsive';
// config
import { HEADER, NAV } from '../../../config-global';
// components
import Logo from '../../../components/logo';
//
import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
import NotificationsPopover from './NotificationsPopover';
import { useEffect, useState } from 'react';
import { db } from 'src/firebase/config';
import { collection, limit, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import { optionsState, userState, usersState } from 'src/recoil/atoms/auth';
import { myTasksState, receivedTasksState } from 'src/recoil/atoms/tasks';
import { realTimeSnapsForMyTasks, realTimeSnapsForReceivedTasks, realTimeSnapsForTaggedTasks } from 'src/recoil/funtions/tasks';
import { realTimeSnapsForMyInvitations, realTimeSnapsForReceivedInvitations } from 'src/recoil/funtions/invitations';
import { myInvitationsState, receivedInvitationsState } from 'src/recoil/atoms/invitations';
import { realTimeSnapsForUsers } from 'src/recoil/funtions/auth';
import { useSnackbar } from 'notistack';
import { initializeSettings } from 'src/utils/helpers';

// ----------------------------------------------------------------------

Header.propTypes = {
  onOpenNav: PropTypes.func,
};

export default function Header({ onOpenNav }) {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const user = useRecoilValue(userState)

  const setUsers = useSetRecoilState(usersState)
  const setMyTasks = useSetRecoilState(myTasksState)
  const setReceivedTasks = useSetRecoilState(receivedTasksState)
  const setMyInvitations = useSetRecoilState(myInvitationsState)
  const setReceivedInvitations = useSetRecoilState(receivedInvitationsState)
  const options = useRecoilValue(optionsState)
  const setOptions = useSetRecoilState(optionsState)

  const [receivedNoti, setReceivedNoti] = useState([]);

  const isNavHorizontal = true;

  const isNavMini = false;

  const isDesktop = useResponsive('up', 'lg');

  const isOffset = useOffSetTop(HEADER.H_DASHBOARD_DESKTOP) && !isNavHorizontal;

  useEffect(() => {
    if (db && user) {
      const unsubscribeUsers = realTimeSnapsForUsers(user, setUsers)
      const unsubscribeMyTasks = realTimeSnapsForMyTasks(user, enqueueSnackbar, setMyTasks)
      const unsubscribeReceivedTasks = realTimeSnapsForReceivedTasks(user, enqueueSnackbar, setReceivedTasks)
      const unsubscribeTaggedTasks = realTimeSnapsForTaggedTasks(user, enqueueSnackbar, setReceivedTasks)
      const unsubscribeMyInvitations = realTimeSnapsForMyInvitations(user, setMyInvitations)
      const unsubscribeReceivedInvitations = realTimeSnapsForReceivedInvitations(user, setReceivedInvitations)
    }
  }, [user])

  useEffect(() => {
    initializeSettings(options, setOptions)
  }, [])

  const renderContent = (
    <>
      <Logo sx={{ mr: 2.5 }} />

      <Searchbar />

      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={{ xs: 0.5, sm: 1.5 }}
      >

        <NotificationsPopover receivedNoti={receivedNoti} />
        <AccountPopover />
      </Stack>
    </>
  );

  return (
    <AppBar
      sx={{
        boxShadow: 'none',
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(isDesktop && {
          width: `calc(100% - ${NAV.W_DASHBOARD + 1}px)`,
          height: HEADER.H_DASHBOARD_DESKTOP,
          ...(isOffset && {
            height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
          }),
          ...(isNavHorizontal && {
            width: 1,
            bgcolor: 'background.default',
            height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
          }),
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_DASHBOARD_MINI + 1}px)`,
          }),
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
}
