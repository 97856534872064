import { Navigate } from 'react-router-dom';
import { useRecoilValue } from "recoil";
// routes
import { PATH_DASHBOARD } from '../routes/paths';

import { userState } from '../recoil/atoms/auth';


// ----------------------------------------------------------------------

export default function GuestGuard({ children }) {
  const user = useRecoilValue(userState);
  if (user) {
    return <Navigate to={PATH_DASHBOARD.root} />;
  }

  return <> {children} </>;
}
