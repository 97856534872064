import PropTypes, { number } from 'prop-types';
import { forwardRef } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import { Badge, Avatar } from '@mui/material';

// ----------------------------------------------------------------------

const getCharAtName = (name) => !isNaN(name) ? name : `${name?.split(" ")?.[0]?.charAt(0)?.toUpperCase()}${name?.split(" ")?.[1]?.charAt(0)?.toUpperCase()}`;

const getColorByName = (name) => {
  if (['A', 'N', 'H', 'L', 'Q', 1].includes(name)) return 'primary';
  if (['F', 'G', 'T', 'I', 'J', 2].includes(name)) return 'info';
  if (['K', 'D', 'Y', 'B', 'O', 3].includes(name)) return 'success';
  if (['P', 'E', 'R', 'S', 'U', 4].includes(name)) return 'warning';
  if (['V', 'W', 'X', 'M', 'Z', 5, 6, 7, 8, 0,].includes(name)) return 'error';
  return 'default';
};

// ----------------------------------------------------------------------

const CustomAvatar = forwardRef(({ color, BadgeProps, children, sx, ...other }, ref) => {
  const theme = useTheme();
  const { alt, ...rest } = other

  const charAtName = getCharAtName(other?.name);

  const colorByName = getColorByName(other?.alt);

  let colr = color || colorByName;

  colr = colr === 'default' ? 'primary' : colr

  const renderContent =
    colr === 'default' ? (
      <Avatar ref={ref} sx={{ ...sx }} {...rest} alt={other?.name}>
        {other?.alt && charAtName}
        {children}
      </Avatar>
    ) :
      (
        <Avatar
          ref={ref}
          sx={{
            color: theme.palette[colr]?.contrastText,
            backgroundColor: theme.palette[colr]?.main,
            fontWeight: theme.typography.fontWeightMedium,
            ...sx,
          }}
          alt={other?.name}
          {...rest}
        >
          {charAtName || other?.alt || '?'}
          {children}
        </Avatar>
      );

  return BadgeProps ? (
    <Badge
      overlap="circular"
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      {...BadgeProps}
    >
      {renderContent}
    </Badge>
  ) : (
    renderContent
  );
});

CustomAvatar.propTypes = {
  sx: PropTypes.object,
  name: PropTypes.string,
  children: PropTypes.node,
  BadgeProps: PropTypes.object,
  color: PropTypes.oneOf([
    'default',
    'primary',
    'secondary',
    'info',
    'success',
    'warning',
    'error',
  ]),
};

export default CustomAvatar;
