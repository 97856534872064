const SUPPORT_URL = 'mailto:support@canyouapp.com?subject=CanYou Support Request'
const TERMS_URL = 'https://www.canyouapp.com/terms'
const PRIVACY_URL = 'https://www.canyouapp.com/privacy'
const baseCloudUrl = 'https://us-central1-canyou-app.cloudfunctions.net'
const PLAY_STORE_URL = 'https://play.google.com/store/apps/details?id=com.canyou'
const APP_STORE_URL = 'https://apps.apple.com/us/app/canyou/id1627695368'

const colors = {
    swipeDelete: '#EF5350',
    swipeArchive: '#A9A9A9',
    swipeCando: '#ffc40c',
    swipeDone: '#228B22'
}

export {
    SUPPORT_URL,
    TERMS_URL,
    PRIVACY_URL,
    baseCloudUrl,
    colors,
    PLAY_STORE_URL,
    APP_STORE_URL
}