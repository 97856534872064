import { Navigate, useRoutes } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { userState } from "../recoil/atoms/auth";
import { DashboardPage, LoginPage, MaintenancePage, Page404, SettingsPage, ProfilePage } from "./elements";
import GuestGuard from "../auth/GuestGuard";
import AuthGuard from "../auth/AuthGuard";
import CompactLayout from '../layouts/compact';
import { PATH_AUTH, PATH_PAGE } from "./paths";
import DashboardLayout from "src/layouts/dashboard/DashboardLayout";

export default function Router() {

    return useRoutes([
        {
            path: '',
            element: <Navigate to={PATH_AUTH.login} />
        },
        // Auth
        {
            path: 'auth',
            element: <CompactLayout />,
            children: [
                {
                    path: 'login',
                    element: (
                        <GuestGuard>
                            <LoginPage />
                        </GuestGuard>
                    ),
                },]
        },
        {
            path: 'dashboard',
            element: <AuthGuard>
                <DashboardLayout />
            </AuthGuard>,
            children: [
                {
                    path: '',
                    element: (
                        <DashboardPage />
                    )
                },
                {
                    path: 'user',
                    children: [
                        {
                            path: 'profile',
                            element: (
                                <ProfilePage />
                            )
                        },
                        {
                            path: 'account',
                            element: (
                                <SettingsPage />
                            )
                        }
                    ]
                },
            ]
        },
        {
            element: <CompactLayout />,
            children: [
                { path: 'maintenance', element: <MaintenancePage /> },
                { path: '404', element: <Page404 /> },
            ],
        },
        // { path: 'safe-browsing', element: <Navigate to="/safe-browsinge" replace /> },
        { path: '*', element: <Navigate to={PATH_PAGE.page404} replace /> },
    ])
}