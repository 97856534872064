import { atom } from "recoil";
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

export const userState = atom({
    key: 'user',
    default: null,
    effects_UNSTABLE: [
        persistAtom,
    ],
})

export const usersState = atom({
    key: 'users',
    default: [],
    effects_UNSTABLE: [
        persistAtom,
    ],
})

export const optionsState = atom({
    key: 'options',
    default: [],
    effects_UNSTABLE: [
        persistAtom,
    ],
})

export const welcomeModalState = atom({
    key: 'welcomeModal',
    default: false,
    effects_UNSTABLE: [
        persistAtom,
    ],
})

export const recentDevicesState = atom({
    key: 'recentDevices',
    default: [],
    effects_UNSTABLE: [
        persistAtom,
    ],
})

export const localLoaderState = atom({
    key: 'localLoader',
    default: false
})