import { atom } from "recoil";
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

export const myTasksState = atom({
    key: 'myTasks',
    default: [],
    effects_UNSTABLE: [
        persistAtom,
    ],
})

export const receivedTasksState = atom({
    key: 'receivedTasks',
    default: [],
    effects_UNSTABLE: [
        persistAtom,
    ],
})

export const searchQueryState = atom({
    key: 'searchQuery',
    default: ''
})