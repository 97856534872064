import { db, storage } from 'src/firebase/config';
import { collection, limit, onSnapshot, orderBy, query, where, doc, getDoc, updateDoc, deleteDoc, setDoc } from 'firebase/firestore';
import { getDate, getDocWithQ, sendNotification, sendTextMessage, uploadFile } from "src/utils/helpers";
import { deleteObject, ref } from 'firebase/storage';

export const createTask = async (
    taskData, enqueueSnackbar,
    navigate, resend = false,
    users = null, handleSuccess = () => null,
    setLoading = () => null, setDetail = () => null) => {
    try {
        const taskRef = doc(collection(db, 'Tasks'))

        if ((taskData.imageUrl || taskData.videoUrl || taskData.audioUrl) && !resend) {
            if (taskData.imageUrl) {
                taskData.imageUrl = await uploadFile(taskData.imageUrl, `task-medias/${taskRef.id}`);
            }
            if (taskData.videoUrl) {
                taskData.videoUrl = await uploadFile(taskData.videoUrl, `task-medias/${taskRef.id}`);
            }
            if (taskData.audioUrl) {
                taskData.audioUrl = await uploadFile(taskData.audioUrl, `task-audios/${taskRef.id}`);
            }
        }

        //? Change userData to get from redux instead of store after fixing UU issue
        let receiverData;
        if (taskData.receiver?.uid?.includes("null")) {
            let checkDb = await getDocWithQ('Users', [where('phoneNumber', '==', taskData.receiver?.phoneNumber)])
            if (checkDb.empty) {
                receiverData = { ...taskData.receiver, uid: taskData.receiver?.phoneNumber }
            }
            else {
                receiverData = checkDb.docs[0].data()
            }
        }
        else {
            receiverData = { ...taskData.receiver }
        }

        //? Change userData to get from redux instead of store after fixing UU issue
        let taggedUsersData = [];
        await Promise.all(taskData.taggedUsers.map(async taggedUser => {
            if (taggedUser.includes('+')) {
                let checkDb = await getDocWithQ('Users', [where('phoneNumber', '==', taggedUser)]);
                if (checkDb.empty) {
                    taggedUsersData.push(taggedUser)
                }
                else {
                    taggedUsersData.push(checkDb.docs[0].data()?.uid)
                }
            }
            else {
                taggedUsersData.push(taggedUser)
            }
        }))

        await setDoc(taskRef, { ...taskData, sender: taskData.sender?.uid, receiver: receiverData.uid, taggedUsers: taggedUsersData, notifyTaggedUsers: taggedUsersData, description: taskData?.description?.trim() || null });

        handleSuccess();
        navigate(`/dashboard?tab=${taskData.sender?.uid === receiverData.uid ? 0 : 1}&taskId=${taskRef.id}`)
        setDetail(true)

        if (taskData.sender?.uid !== receiverData.uid) {
            if (receiverData?.fcmToken) {
                sendNotification(receiverData.fcmToken, `New CanYou by ${taskData.sender.firstName} ${taskData.sender.lastName}`, `${taskData?.imageUrl ? '📷 ' : taskData?.audioUrl ? '🎙️ ' : taskData?.videoUrl ? '🎥 ' : ''}${taskData?.description || 'Media'}`, receiverData?.uid, { taskId: taskRef.id })
            }
            else {
                if (receiverData.uid?.includes('+')) {
                    sendTextMessage(`${taskData.sender.firstName} ${taskData.sender.lastName} tagged you in this task: ${taskData.description}\n
  Apple:
  https://apps.apple.com/us/app/canyou/id1627695368\n
  Android:
  https://play.google.com/store/apps/details?id=com.canyou
  `, taskData.receiver?.phoneNumber)
                }
                else {
                    let checkDb = await getDocWithQ('Users', [where('uid', '==', receiverData.uid)]);
                    sendNotification(checkDb.docs[0].data()?.fcmToken, `New CanYou by ${taskData.sender.firstName} ${taskData.sender.lastName}`, `${taskData?.imageUrl ? '📷 ' : taskData?.audioUrl ? '🎙️ ' : taskData?.videoUrl ? '🎥 ' : ''}${taskData?.description || 'Media'}`, receiverData?.uid, { taskId: taskRef.id })
                }
            }
        }
        let taggedNoti = [];
        if (!!taggedUsersData?.length) {
            await Promise.all(taggedUsersData.map(async taggedUser => {
                if (taggedUser.includes('+')) {
                    sendTextMessage(`${taskData.sender.firstName} ${taskData.sender.lastName} tagged you in this task: ${taskData.description}\n
  Apple:
  https://apps.apple.com/us/app/canyou/id1627695368\n
  Android:
  https://play.google.com/store/apps/details?id=com.canyou
  `, taggedUser)
                    taggedNoti.push(taggedUser)
                }
                else {
                    if (users?.find(user => user.uid === taggedUser)?.fcmToken) {
                        sendNotification(users?.find(user => user.uid === taggedUser)?.fcmToken, `You have been tagged by ${taskData.sender.firstName} ${taskData.sender.lastName} to a task`, `${taskData?.imageUrl ? '📷 ' : taskData?.audioUrl ? '🎙️ ' : taskData?.videoUrl ? '🎥 ' : ''}${taskData?.description || 'Media'}`, taggedUser, { taskId: taskRef.id })
                    }
                    else {
                        let checkDb = await getDocWithQ('Users', [where('uid', '==', taggedUser)]);
                        sendNotification(checkDb.docs[0].data()?.fcmToken, `You have been tagged by ${taskData.sender.firstName} ${taskData.sender.lastName} to a task`, `${taskData?.imageUrl ? '📷 ' : taskData?.audioUrl ? '🎙️ ' : taskData?.videoUrl ? '🎥 ' : ''}${taskData?.description || 'Media'}`, taggedUser, { taskId: taskRef.id })
                    }
                }
            }))
        }
    } catch ({ message }) {
        setLoading(false)
        enqueueSnackbar(message, { variant: 'error' });
    }
}

const getUser = (id) => new Promise(async (resolve) => {
    const ref = doc(db, 'Users', id)
    const data = await getDoc(ref)
    resolve(data.data())
})

const createTaskInRecoil = (payload, setTasks) => {
    setTasks(prevTasks => {
        const tasks = [payload, ...prevTasks].filter((payload, index, self) =>
            index === self.findIndex((t) => (
                t.id === payload.id
            ))
        )
        return tasks
    })
}

const updateTasksInRecoil = (payload, setState) => {
    let { taskId, notifySender, notifyReceiver, notifyTaggedUsers, status, lastComment, animatedStatus, taggedUsers, archivedUsers, description } = payload;
    setState(tasks => {
        let tasksClone = [...tasks]
        let oldTaskIndex = tasksClone.findIndex(task => task.id === taskId)
        let oldTask = tasksClone.find(task => task.id === taskId)
        let updatedTasks;
        if (oldTask?.lastComment?.id !== lastComment?.id) {
            tasksClone.splice(oldTaskIndex, 1)
            updatedTasks = [{ ...oldTask, status, notifySender, notifyReceiver, notifyTaggedUsers, lastComment, animatedStatus, taggedUsers, archivedUsers, description }, ...tasksClone]
        }
        else {
            tasksClone.splice(oldTaskIndex, 1, { ...oldTask, status, notifySender, notifyReceiver, notifyTaggedUsers, lastComment, animatedStatus, taggedUsers, archivedUsers, description })
            updatedTasks = tasksClone
        }
        return updatedTasks
    })
}

const deleteTaskInRecoil = (payload, setState) => {
    let { taskId } = payload;
    setState(tasksClone => {
        return tasksClone.filter(task => task.id !== taskId)
    })
}

export const updateTaskInStorage = async (taskFromDb, setState, enqueueSnackbar) => {
    try {
        const data = { ...taskFromDb };
        let lastComment = await getDocWithQ('Comments', [where('taskId', '==', data.id), orderBy("dateCreated", "desc"), limit(1)])
        if (lastComment.empty) {
            data.lastComment = null
        }
        else {
            data.lastComment = { ...lastComment.docs[0].data(), id: lastComment.docs[0].id, dateCreated: lastComment.docs[0].data().dateCreated ? getDate(lastComment.docs[0].data().dateCreated) : lastComment.docs[0].data().dateCreated }
        }

        updateTasksInRecoil({ taskId: taskFromDb.id, notifySender: taskFromDb.notifySender, notifyReceiver: taskFromDb.notifyReceiver, notifyTaggedUsers: taskFromDb.notifyTaggedUsers, status: taskFromDb.status, receiver: taskFromDb.receiver, lastComment: data.lastComment, animatedStatus: taskFromDb.animatedStatus, taggedUsers: taskFromDb.taggedUsers, archivedUsers: taskFromDb.archivedUsers, description: taskFromDb.description },
            setState
        )

    } catch ({ message }) {
        enqueueSnackbar(message);
    }
}

export const saveTaskInStorage = async (authUser, taskFromDb, setTasks, enqueueSnackbar) => {
    try {
        const data = { ...taskFromDb };
        data.dateCreated = data.dateCreated ? getDate(data.dateCreated) : data.dateCreated
        data.lastMessage = data.lastMessage ? getDate(data.lastMessage) : data.lastMessage

        data.sender = data.sender === authUser?.uid ? authUser : (await getUser(data.sender))
        if (data.receiver === authUser?.uid) {
            data.receiver = authUser
        }
        else if (data.receiver?.includes('+')) {
            const contactInfo = null//(await check(Platform.OS === "android" ? PERMISSIONS.ANDROID.READ_CONTACTS : PERMISSIONS.IOS.CONTACTS)) === "granted" ? (await Contacts.getContactsByPhoneNumber(data.receiver))?.[0] : null
            data.receiver = {
                phoneNumber: data.receiver,
                firstName: contactInfo?.givenName || "User",
                lastName: contactInfo?.familyName || " "
            }
        }
        else {
            data.receiver = await getUser(data.receiver)
        }
        let lastComment = await getDocWithQ('Comments', [where('taskId', '==', data.id), orderBy("dateCreated", "desc"), limit(1)])
        if (lastComment.empty) {
            data.lastComment = null
        }
        else {
            data.lastComment = { ...lastComment.docs[0].data(), id: lastComment.docs[0].id, dateCreated: lastComment.docs[0].data().dateCreated ? getDate(lastComment.docs[0].data().dateCreated) : lastComment.docs[0].data().dateCreated }
        }
        createTaskInRecoil(data, setTasks)
    } catch ({ message }) {
        enqueueSnackbar(message)
    }
}

export const realTimeSnapsForMyTasks = (user, enqueueSnackbar, setMyTasks) => {
    const tasksRef = collection(db, 'Tasks')
    const myTasksQuery = query(
        tasksRef,
        where('sender', '==', user.uid),
        where('receiver', '!=', user.uid)
    )

    return onSnapshot(myTasksQuery, (snapshot) => {
        snapshot.docChanges()?.forEach(async (change, ind, items) => {

            let { type, doc } = change;
            if (type === "added") {
                saveTaskInStorage(user, { ...doc.data(), id: doc.id }, setMyTasks, enqueueSnackbar)
            }
            else if (type === "modified") {
                updateTaskInStorage({ ...doc.data(), id: doc.id }, setMyTasks, enqueueSnackbar)
            }
            else if (type === "removed") {
                deleteTaskInRecoil({ taskId: doc.id }, setMyTasks)
            }
        })
    })
}

export const realTimeSnapsForReceivedTasks = (user, enqueueSnackbar, setReceivedTasks) => {
    const tasksRef = collection(db, 'Tasks')
    const myTasksQuery = query(
        tasksRef,
        where('receiver', '==', user.uid)
    )

    return onSnapshot(myTasksQuery, (snapshot) => {
        snapshot.docChanges()?.forEach(async (change, ind, items) => {

            let { type, doc } = change;
            if (type === "added") {
                saveTaskInStorage(user, { ...doc.data(), id: doc.id }, setReceivedTasks, enqueueSnackbar)
            }
            else if (type === "modified") {
                updateTaskInStorage({ ...doc.data(), id: doc.id }, setReceivedTasks, enqueueSnackbar)
            }
            else if (type === "removed") {
                deleteTaskInRecoil({ taskId: doc.id }, setReceivedTasks)
            }
        })
    })
}

export const realTimeSnapsForTaggedTasks = (user, enqueueSnackbar, setReceivedTasks) => {
    const tasksRef = collection(db, 'Tasks')
    const myTasksQuery = query(
        tasksRef,
        where("taggedUsers", "array-contains", user.uid)
    )

    return onSnapshot(myTasksQuery, (snapshot) => {
        snapshot.docChanges()?.forEach(async (change, ind, items) => {

            let { type, doc } = change;
            if (type === "added") {
                saveTaskInStorage(user, { ...doc.data(), id: doc.id }, setReceivedTasks, enqueueSnackbar)
            }
            else if (type === "modified") {
                updateTaskInStorage({ ...doc.data(), id: doc.id }, setReceivedTasks, enqueueSnackbar)
            }
            else if (type === "removed") {
                deleteTaskInRecoil({ taskId: doc.id }, setReceivedTasks)
            }
        })
    })
}

export const updateTask = async (task, taskId) => {
    try {
        await updateDoc(doc(db, 'Tasks', taskId), task)
    } catch (err) {
    }
}

export const deleteTask = async (taskId, isMedia, isAudio) => {
    try {
        const taskRef = doc(db, 'Tasks', taskId);
        const taskMessages = await getDocWithQ('Comments', [where('taskId', '==', taskId)])

        await Promise.all(taskMessages.docs?.map(async doc => {
            if (doc.data().imageUrl || doc.data().videoUrl) {
                await deleteObject(ref(storage, `comment-medias/${doc.id}`))
            }
            if (doc.data().audioUrl) {
                await deleteObject(ref(storage, `comment-audios/${doc.id}`))
            }
            await deleteDoc(doc.ref)
        }))

        if (isMedia) {
            await deleteObject(ref(storage, `task-medias/${taskId}`))
        }
        if (isAudio) {
            await deleteObject(ref(storage, `task-audios/${taskId}`))
        }

        await deleteDoc(taskRef)

        return Promise.resolve()
    } catch ({ message }) {
        console.log(message, 'deleteTask')
        return Promise.reject({ message })
    }
}