import { format, getTime, formatDistanceToNow } from 'date-fns';

// ----------------------------------------------------------------------

export function fDate(date = '', newFormat) {
  const newDate = date ? new Date(date) : new Date()
  const fm = newFormat || format(newDate, 'yyyy') === format(new Date(), 'yyyy') ? 'MMM dd' : 'MMM dd yyyy';
  return format(newDate, fm)
}

export function fDateTime(date = '', newFormat) {
  const fm = newFormat || 'dd MMM yyyy p';

  return format(new Date(date), fm)
}

export function fTimestamp(date = '') {
  return getTime(new Date(date))
}

export function fToNow(date = '') {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
  })

}
