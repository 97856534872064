import { LoadingButton } from "@mui/lab";
import { Box, Button, Modal, Stack, Typography, Checkbox, Link } from "@mui/material"
import { collection, doc, getDoc, getDocs, limit, orderBy, query, updateDoc, where } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { db, storage } from "src/firebase/config";
import SendTaskAudio from '../assets/send_task.wav'
import VoiceNoteAudio from '../assets/voice_note.wav'
import { baseCloudUrl } from "./constants";

export const ConfirmationPrompt = ({ isOpen, onClose, accept, loading = false, message, ...rest }) => {

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };
    return (
        <Modal
            open={isOpen}
            onClose={onClose}
            aria-labelledby={"modal-modal-title"}
            aria-describedby={"modal-modal-description"}
            {...rest}
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Confirmation.
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    {message}
                </Typography>
                <Stack justifyContent="flex-end" sx={{ mt: 3 }} flexDirection={'row'} gap={2}>
                    <Button type='button' variant="outlined" onClick={onClose}>No</Button>
                    <LoadingButton
                        color={'error'}
                        type="button" variant="contained" loading={loading} onClick={accept}>
                        Yes
                    </LoadingButton>
                </Stack>
            </Box>
        </Modal>
    )
}
export const uploadFile = (file, path) =>
    new Promise((resolve, reject) => {
        if (!file) return
        const storageRef = ref(
            storage,
            path
        )
        const uploadTask = uploadBytesResumable(storageRef, file)
        uploadTask.on(
            'state_changed',
            // don't want progress
            () => null,
            (error) => {
                reject(error)
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    resolve(downloadURL)
                })
            }
        )
    })


export const e164FormatPhoneNumberValidation = (phoneNumber) => {
    let regex = /^\+[1-9]\d{10,14}$/;
    return regex.test(phoneNumber)
}

export const getDate = (firebaseDateTime) => {
    if (typeof (firebaseDateTime) === "string") {
        return firebaseDateTime
    }
    return new Date(
        firebaseDateTime.seconds * 1000 + firebaseDateTime.nanoseconds / 1000000,
    ).toISOString();
}

export const getDocWithQ = (collectionName, allQueries) => new Promise(async (resolve) => {
    const ref = collection(db, collectionName)

    const q = query(
        ref,
        ...allQueries
    )
    const snap = await getDocs(q)

    resolve(snap)
})

export const getNotArchivedTasks = (tasks, myUid) => {
    return tasks?.filter(task => !task.archivedUsers?.includes(myUid));
}

export const showNotificationIcon = (currentUserUid, senderId, receiverId, taggedUsers, notifySender, notifyReceiver, notifyTaggedUsers) => {
    return (currentUserUid === senderId && notifySender) || (currentUserUid === receiverId && notifyReceiver) || (taggedUsers?.includes(currentUserUid) && notifyTaggedUsers?.includes(currentUserUid));
}

export const noOfUnreadTasks = (tasks, user, tabNum) => {
    let numberOfNotifications = tasks?.filter(task =>
        tabNum === 1 ? (user?.uid === task.sender?.uid && task.notifySender) : ((user?.uid === task.receiver?.uid && task.notifyReceiver) || (task.taggedUsers?.includes(user?.uid) && task.notifyTaggedUsers?.includes(user?.uid)))
    ) || []

    return numberOfNotifications?.length || 0
}

export const shouldSortTaskVisible = (tasks) => {
    return tasks?.length >= 5 || !!tasks?.filter(task => task.status === "Done!")?.length
}

export const statusIcons = {
    'Can Do!': '/assets/images/thumbsup.png',
    'Done!': '/assets/images/check.png',
    'Archived': '/assets/images/archiveWhite.png'
};

export const getRandomColor = (index) => {
    const colorArray = [
        "primary",
        "info",
        "success",
        "warning",
        "info",
        "primary",
    ];
    return colorArray[index];
};

export const splitDate = (date) => {
    const splittedDate = date.split("/");
    return {
        day: splittedDate[1],
        month: splittedDate[0],
    };
};

export const formatDate = (date) => {
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    const newDate = new Date(date);
    return `${monthNames[newDate.getMonth()]}/${newDate.getDate()}`;
}

export const descriptionExtractor = (description) => {
    if (description) {
        let sentence = description.toLowerCase();
        let word = "CanYou please".toLowerCase();
        let wordIndex = sentence.indexOf(word);
        if (wordIndex != -1) {
            let lastIndex = wordIndex + word.length;
            return description.slice(lastIndex).trim()
        }
    }
    return description
}

export const UrlMutatedText = ({ string, setSelectedNumber, originalString, docId, isTask = false }) => {
    let urlRegex = /(https?:\/\/[^\s]+|www\.[^\s]+|\b[\w-]+\.[\w]{2,}\b)/g;
    let phoneRegex = /(\+\d{1,3}\s?)?((\(\d{3}\)-?\s?)|(\d{3})(\s|-?))(\d{3}(\s|-?))(\d{4})(\s?(([E|e]xt[:|.|]?)|x|X)(\s?\d+))?/g;
    let parts = string.split(urlRegex);
    let original;
    if (originalString) {
        original = originalString.split(urlRegex)
    }
    let finalString = parts.map((part, i) => {
        if (urlRegex.test(part)) {
            return <Link sx={{ verticalAlign: 'middle' }} target="_blank" key={i} href={original ? original.find(org => org.startsWith(part.slice(0, -3)))?.replace(/^(?!https?:\/\/)/, "https://") : part.replace(/^(?!https?:\/\/)/, "https://")}>{part}</Link>
        }
        else if (!!part) {
            return part.replace(phoneRegex, '{}').split(/(\{\}|[^{}]+)/g).filter(Boolean).map((val, ind) => {
                if ((val === "{}" && !!part.match(phoneRegex).length)) {
                    return <Typography variant="body2" style={{ textDecorationLine: 'underline' }} key={ind}>{part.match(phoneRegex).shift()}</Typography>
                }
                else {
                    // Replace "$checkbox:false" and "$checkbox:true" with checkboxes
                    const checkboxMatch = /*part*/val.split(/(\$check:(?:true|false))\s(.*?)(?=\n|$)/g);
                    let checkBoxNum = 1;
                    return checkboxMatch.map((value, i) => {
                        if (/\$check:(true|false)/g.test(value)) {
                            const isChecked = value.includes('true');
                            const checkboxKey = `checkbox-${checkBoxNum}`;
                            ++checkBoxNum;

                            return (
                                <Checkbox
                                    key={i}
                                    sx={{ color: 'black', padding: 0, mr: 1 }}
                                    size="small"
                                    onChange={async e => {
                                        let check = e.target.checked;
                                        let count = 0;

                                        let updatedString = string.replace(/\$check:(true|false)/g, (match, state) => {
                                            ++count;
                                            if (count == checkboxKey.split('-')[1]) {
                                                return `$check:${check}`; // Replace with your desired value
                                            }
                                            else {
                                                return match;
                                            }
                                        })

                                        if (isTask) {
                                            await updateDoc(doc(db, 'Tasks', docId), { description: updatedString })
                                        }
                                        else {
                                            await updateDoc(doc(db, 'Comments', docId), { description: updatedString })
                                        }
                                    }}
                                    defaultChecked={isChecked}
                                    aria-label={checkboxKey}
                                />
                            );
                        }
                        else {
                            return <span style={{ whiteSpace: 'pre-wrap', verticalAlign: 'middle', wordBreak: 'break-all' }} key={i}>{value}</span>;
                        }
                    })

                    // return <p key={ind}>{val}</p>
                }
            })
        }
    });

    return <Typography variant="body2">{finalString}</Typography>;
}

export const playSound = (type) => {
    new Audio(type == "task" ? SendTaskAudio : VoiceNoteAudio).play()
}

export const initializeSettings = (options, setOptions) => {
    if (!options?.length) {
        [
            {
                id: 1,
                isEnabled: true
            },
            {
                id: 2,
                isEnabled: true
            }
        ]?.forEach((val) => {
            setOptions(prevOptions => ([...prevOptions, val]))
        })
    }
}

export const sendNotification = async (receiverToken, title, body, userUid, data = {}) => {
    try {
        if (receiverToken) {
            let payload = JSON.stringify({
                to: receiverToken,
                notification: {
                    title,
                    body
                },
                data,
                userUid
            });

            const url = baseCloudUrl + '/sendNotification'
            await fetch(url, {
                method: "POST",
                body: payload
            })
        }
    } catch (err) {
        console.log('error sending notification', err)
    }
}

export const sendTextMessage = async (message, updatedPhoneNumber) => {
    try {
      let payload = JSON.stringify({
        to: updatedPhoneNumber,
        body: message
      });
  
      const url = baseCloudUrl + '/sendTextMessage'
      const res = await fetch(url, {
        method: "POST",
        body: payload
      })
      const response = JSON.parse(await res.text())
  
      if (response?.statusCode !== 200) throw response
      return true
  
    } catch (err) {
      return err
    }
  }