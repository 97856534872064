import { Suspense, lazy } from 'react';
// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) =>
(
    <Suspense fallback={<LoadingScreen />}>
        <Component {...props} />
    </Suspense>
);


export const DashboardPage = Loadable(lazy(() => import('../pages/Dashboard/Dashboard')));
export const SettingsPage = Loadable(lazy(() => import('../pages/Settings/Settings')));
export const ProfilePage = Loadable(lazy(() => import('../pages/Profile/Profile')));
export const LoginPage = Loadable(lazy(() => import('../pages/auth/LoginPage')));
export const MaintenancePage = Loadable(lazy(() => import('../pages/MaintenancePage')));
export const Page404 = Loadable(lazy(() => import('../pages/Page404')));