import { useState } from 'react';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { userState, usersState } from 'src/recoil/atoms/auth';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem } from '@mui/material';
// routes
import { PATH_DASHBOARD, PATH_AUTH } from '../../../routes/paths';
// auth
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// components
import { CustomAvatar } from '../../../components/custom-avatar';
import { useSnackbar } from '../../../components/snackbar';
import MenuPopover from '../../../components/menu-popover';
import { IconButtonAnimate } from '../../../components/animate';
import { signOut } from 'firebase/auth';
import { auth } from '../../../firebase/config'
import { myTasksState, receivedTasksState } from 'src/recoil/atoms/tasks';
import { myInvitationsState, receivedInvitationsState } from 'src/recoil/atoms/invitations';
import { commentsState } from 'src/recoil/atoms/comments';

// ----------------------------------------------------------------------

const OPTIONS = [
  {
    label: 'Home',
    linkTo: PATH_DASHBOARD.root,
  },
  {
    label: 'Profile',
    linkTo: PATH_DASHBOARD.user.profile,
  },
  {
    label: 'Settings',
    linkTo: PATH_DASHBOARD.user.account,
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const logoutRecoil = useResetRecoilState(userState)
  const [user] = useRecoilState(userState)
  const resetUsers = useResetRecoilState(usersState)
  const resetMyTasks = useResetRecoilState(myTasksState)
  const resetReceivedTasks = useResetRecoilState(receivedTasksState)
  const resetMyInvitations = useResetRecoilState(myInvitationsState)
  const resetComments = useResetRecoilState(commentsState)
  const resetReceivedInvitations = useResetRecoilState(receivedInvitationsState)
  const isMountedRef = useIsMountedRef();

  const { enqueueSnackbar } = useSnackbar();

  const [openPopover, setOpenPopover] = useState(null);

  const handleOpenPopover = (event) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      logoutRecoil()

      enqueueSnackbar('Logout Successfully!');

      resetMyInvitations()
      resetReceivedInvitations()
      resetUsers()
      resetMyTasks()
      resetReceivedTasks()
      resetComments()

      navigate('/auth/login');
      if (isMountedRef.current) {
        handleClosePopover();
      }


    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  const handleClickItem = (path) => {
    handleClosePopover();
    navigate(path);
  };

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpenPopover}
        sx={{
          p: 0,
          ...(openPopover && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <CustomAvatar variant="round" src={user?.imageUrl} alt={user?.colorIndex || user?.firstName}
          name={user?.firstName + " " + user?.lastName} />
      </IconButtonAnimate>

      <MenuPopover open={openPopover} onClose={handleClosePopover} sx={{ width: 200, p: 0 }}>
        <Box sx={{ my: 1.5, px: 2 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.firstName + " " + user?.lastName}
          </Typography>

          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user?.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {OPTIONS
            .filter((v) => v.linkTo !== pathname)
            .map((option) => (
              <MenuItem key={option.label} onClick={() => handleClickItem(option.linkTo)}>
                {option.label}
              </MenuItem>
            ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          Logout
        </MenuItem>
      </MenuPopover>
    </>
  );
}
