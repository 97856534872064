import { m } from 'framer-motion';
import { useLocation } from 'react-router-dom';
// @mui
import { alpha, styled } from '@mui/material/styles';
import { Box, LinearProgress, Typography } from '@mui/material';
// hooks
import useResponsive from '../../hooks/useResponsive';
// config
import { NAV, HEADER } from '../../config-global';
// auth
import ProgressBar from '../progress-bar';
import Lottie from 'react-lottie'
import { LoaderAnimation } from 'src/assets/lottie'

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  right: 0,
  bottom: 0,
  zIndex: 9998,
  width: '100%',
  height: '100%',
  position: 'fixed',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.background.default,
}));

// ----------------------------------------------------------------------

export default function LoadingScreen({ lottie }) {
  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'lg');

  const isDashboard = isDesktop;

  const size =
    (NAV.W_DASHBOARD_MINI) ||
    (NAV.W_DASHBOARD) ||
    144;

  return (
    <>
      <ProgressBar />

      <StyledRoot
        sx={{
          // ...(isDashboard && {
          //   width: `calc(100% - ${size}px)`,
          //   height: `calc(100% - ${HEADER.H_DASHBOARD_DESKTOP}px)`,
          //   ...('horizontal' && {
          //     width: 1,
          //     height: `calc(100% - ${size}px)`,
          //   }),
          // }),
          ...(lottie && { background: 'rgba(255, 255, 255, 0.9)' })
        }}
      >
        {/* {isDashboard ? (
          <LinearProgress color="inherit" sx={{ width: 1, maxWidth: 360 }} />
        ) : ( */}
          <>
            <m.div
              animate={{
                scale: [1, 0.9, 0.9, 1, 1],
                opacity: [1, 0.48, 0.48, 1, 1],
              }}
              transition={{
                duration: 2,
                ease: 'easeInOut',
                repeatDelay: 1,
                repeat: Infinity,
              }}
            >
              {/* <Typography variant='h3'>CanYou</Typography> */}
              {lottie ? <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: LoaderAnimation,
                  rendererSettings: {
                    preserveAspectRatio: "xMidYMid slice"
                  }
                }}
                height={500}
                width={500}
              /> : <img src="/assets/images/canyouLogo.png" style={{ width: 70, height: 70 }} />}
              {/* <Logo disabledLink sx={{ width: 64, height: 64 }} /> */}
            </m.div>

            <Box
              component={m.div}
              animate={{
                scale: [1.6, 1, 1, 1.6, 1.6],
                rotate: [270, 0, 0, 270, 270],
                opacity: [0.25, 1, 1, 1, 0.25],
                borderRadius: ['25%', '25%', '50%', '50%', '25%'],
              }}
              transition={{ ease: 'linear', duration: 3.2, repeat: Infinity }}
              sx={{
                width: 100,
                height: 100,
                position: 'absolute',
                border: (theme) => lottie ? '' : `solid 3px ${alpha(theme.palette.primary.dark, 0.24)}`,
              }}
            />

            <Box
              component={m.div}
              animate={{
                scale: [1, 1.2, 1.2, 1, 1],
                rotate: [0, 270, 270, 0, 0],
                opacity: [1, 0.25, 0.25, 0.25, 1],
                borderRadius: ['25%', '25%', '50%', '50%', '25%'],
              }}
              transition={{
                ease: 'linear',
                duration: 3.2,
                repeat: Infinity,
              }}
              sx={{
                width: 120,
                height: 120,
                position: 'absolute',
                border: (theme) => lottie ? '' : `solid 8px ${alpha(theme.palette.primary.dark, 0.24)}`,
              }}
            />
          </>
        {/* )} */}
      </StyledRoot>
    </>
  );
}
