import { useRecoilValue } from 'recoil';
import { userState } from '../recoil/atoms/auth';
import { Navigate } from 'react-router-dom';
import { PATH_AUTH, PATH_DASHBOARD, } from '../routes/paths';

// ----------------------------------------------------------------------

export default function AuthGuard({ children }) {
  const user = useRecoilValue(userState);

  if (!user) {
    return <Navigate to={PATH_AUTH.login} />;
  }
  // if(!!user && !user?.firstName){
  //   return <Navigate to={PATH_DASHBOARD.user.profile} />;
  // }

  return <> {children} </>;
}
