import { db } from 'src/firebase/config';
import { collection, limit, onSnapshot, orderBy, query, where, doc, getDoc, updateDoc, deleteDoc } from 'firebase/firestore';
import { getDate } from "src/utils/helpers";

const createInvitationInRecoil = (payload, setInvitations) => {
    setInvitations(prevInvitations => {
        const invitations = [...prevInvitations, payload].filter((payload, index, self) =>
            index === self.findIndex((t) => (
                t.id === payload.id
            ))
        )
        return invitations
    })
}

const updateInvitationsInRecoil = (payload, setState) => {
    let { invitationId, invitationStatus } = payload;
    setState(invitations => {
        let invitationsClone = [...invitations]
        let oldInvitationIndex = invitationsClone.findIndex(invitation => invitation.id === invitationId)
        let oldInvitation = invitationsClone.find(invitation => invitation.id === invitationId)

        invitationsClone.splice(oldInvitationIndex, 1, { ...oldInvitation, status: invitationStatus })

        return invitationsClone
    })
}

const deleteInvitationInRecoil = (payload, setState) => {
    let { invitationId } = payload;
    setState(invitationsClone => {
        return invitationsClone.filter(invitation => invitation.id !== invitationId)
    })
}

export const updateInvite = async (invitationId, invitationStatus, enqueueSnackbar) => {
    try {
        await updateDoc(doc(db, 'Invitations', invitationId), { status: invitationStatus })
        enqueueSnackbar('Accepted Successfully')

    } catch ({ message }) {
        enqueueSnackbar(message, { variant: 'error' })
    }
}

export const removeInvite = async (invitationId, enqueueSnackbar = null) => {
    try {
        await deleteDoc(doc(db, 'Invitations', invitationId))
        enqueueSnackbar('Removed Successfully')

    } catch ({ message }) {
        enqueueSnackbar(message, { variant: 'error' })
    }
}

export const realTimeSnapsForMyInvitations = (user, setMyInvitations) => {
    const invitationsRef = collection(db, 'Invitations')
    const myInvitationsQuery = query(
        invitationsRef,
        where('invitedBy', '==', user.uid)
    )

    return onSnapshot(myInvitationsQuery, (snapshot) => {
        snapshot.docChanges()?.forEach(async (change, ind, items) => {

            let { type, doc } = change;
            if (type === "added") {
                createInvitationInRecoil({ ...doc.data(), id: doc.id }, setMyInvitations)
            }
            else if (type === "modified") {
                updateInvitationsInRecoil({ invitationId: doc.id, invitationStatus: doc.data().status }, setMyInvitations)
            }
            else if (type === "removed") {
                deleteInvitationInRecoil({ invitationId: doc.id }, setMyInvitations)
            }
        })
    })
}

export const realTimeSnapsForReceivedInvitations = (user, setReceivedInvitations) => {
    const invitationsRef = collection(db, 'Invitations')
    const receivedInvitationsQuery = query(
        invitationsRef,
        where('phoneNumber', '==', user.phoneNumber)
    )

    return onSnapshot(receivedInvitationsQuery, (snapshot) => {
        snapshot.docChanges()?.forEach(async (change, ind, items) => {

            let { type, doc } = change;
            if (type === "added") {
                createInvitationInRecoil({ ...doc.data(), id: doc.id }, setReceivedInvitations)
            }
            else if (type === "modified") {
                updateInvitationsInRecoil({ invitationId: doc.id, invitationStatus: doc.data().status }, setReceivedInvitations)
            }
            else if (type === "removed") {
                deleteInvitationInRecoil({ invitationId: doc.id }, setReceivedInvitations)
            }
        })
    })
}