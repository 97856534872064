import { db } from 'src/firebase/config';
import { collection, limit, onSnapshot, orderBy, query, where, doc, getDoc, updateDoc } from 'firebase/firestore';
import { getDate } from "src/utils/helpers";

const createUserInRecoil = (payload, setUsers) => {
    // setUsers(prevUsers => {
    //     const users = Array.from(new Set([...prevUsers, payload].map(JSON.stringify))).map(JSON.parse)
    //     return users
    // })
    setUsers(prevUsers => {
        const users = [...prevUsers, payload].filter((payload, index, self) =>
            index === self.findIndex((t) => (
                t.id === payload.id
            ))
        )
        return users
    })
}

const updateUsersInRecoil = (payload, setState) => {
    setState(users => {
        let usersClone = [...users]
        let oldUserIndex = usersClone.findIndex(user => user.id === payload.id)

        usersClone.splice(oldUserIndex, 1, payload)

        return usersClone
    })
}

const deleteUserInRecoil = (payload, setState) => {
    let { docId } = payload;
    setState(usersClone => {
        return usersClone.filter(user => user.id !== docId)
    })
}

export const realTimeSnapsForUsers = (user, setUsers) => {
    const usersRef = collection(db, 'Users')
    const usersQuery = query(
        usersRef,
        where('uid', "!=", user.uid)
    )

    return onSnapshot(usersQuery, (snapshot) => {
        snapshot.docChanges()?.forEach(async (change, ind, items) => {

            let { type, doc } = change;
            if (type === "added") {
                createUserInRecoil({ ...doc.data(), id: doc.id }, setUsers)
            }
            else if (type === "modified") {
                updateUsersInRecoil({ ...doc.data(), id: doc.id }, setUsers)
            }
            else if (type === "removed") {
                deleteUserInRecoil({ docId: doc.id }, setUsers)
            }
        })
    })
}