import { atom } from "recoil";
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

export const commentsState = atom({
    key: 'comments',
    default: [],
    effects_UNSTABLE: [
        persistAtom,
    ],
})